<template>
  <div class="email-app-details">

    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="openLeftSidebar(true)"
          />
        </div>
        <span class="go-back ml-1 mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-detail')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col md="12">
              {{ proposalDetail.proposal.sw130s15 }}
              <b-badge
                v-if="Number(proposalDetail.proposal.sw130s27) === 1"
                variant="light-primary"
              >
                {{ $t('Zome Now') }}
              </b-badge>
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>

    <div class="email-app-list position-relative height-100-panel">

      <!-- Listagem -->
      <vue-perfect-scrollbar
        ref="detailProposalScroll"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <b-container
          fluid
        >
          <b-row class="p-2">
            <b-col
              md="12"
              class="mb-1"
            >
              <div class="d-flex justify-content-start">
                <b-img
                  rounded
                  :src="typeof proposalDetail.imagesListing !== 'undefined' && typeof proposalDetail.imagesListing[0] !== 'undefined' ? proposalDetail.imagesListing[0] : proposalDetail.imagePreview"
                  height="100"
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h5 class="mb-0">
                      {{ proposalDetail.listingInfo.tipologia }}
                    </h5>
                    <h6
                      v-if="proposalDetail.listing.currencyL !== ''"
                      class="mb-0"
                    >
                      {{ proposalDetail.listing.currencyL }} {{ formatPrice(proposalDetail.listingInfo.preco) }}
                    </h6>
                    <h6
                      v-if="proposalDetail.listing.currencyR !== ''"
                      class="mb-0"
                    >
                      {{ formatPrice(proposalDetail.listingInfo.preco) }} {{ proposalDetail.listing.currencyR }}
                    </h6>
                    <p class="card-text mb-0">
                      {{ proposalDetail.listingInfo.pid }}
                    </p>
                    <p
                      v-if="countryApp.toUpperCase() === 'PT' && (proposalDetail.listingInfo.level1 !== '' || proposalDetail.listingInfo.level2 !== '')"
                      class="card-text mb-0"
                    >
                      <span v-if="proposalDetail.listingInfo.level2 !== ''">{{ proposalDetail.listingInfo.level2 }}</span>
                      <span v-if="proposalDetail.listingInfo.level1 !== ''"> > <strong>{{ proposalDetail.listingInfo.level1 }}</strong></span>
                    </p>
                    <p
                      v-if="countryApp.toUpperCase() === 'PT' && proposalDetail.listingInfo.level3 !== ''"
                      class="card-text mb-0"
                    >
                      <strong>{{ proposalDetail.listingInfo.level3 }}</strong>
                    </p>
                    <p
                      v-if="countryApp.toUpperCase() !== 'PT' && (proposalDetail.listingInfo.level4 !== '' || proposalDetail.listingInfo.level3 !== '' || proposalDetail.listingInfo.level2 !== '')"
                      class="card-text mb-0"
                    >
                      <span v-if="proposalDetail.listingInfo.level4 !== ''">{{ proposalDetail.listingInfo.level4 }} </span>
                      <span v-if="proposalDetail.listingInfo.level3 !== ''"> > {{ proposalDetail.listingInfo.level3 }}</span>
                      <span v-if="proposalDetail.listingInfo.level2 !== ''"> > {{ proposalDetail.listingInfo.level2 }}</span>
                      <span v-if="proposalDetail.listingInfo.level1 !== ''"> > <strong>{{ proposalDetail.listingInfo.level1 }}</strong></span>
                    </p>
                    <p
                      v-if="countryApp.toUpperCase() !== 'PT' && proposalDetail.listingInfo.level5 !== ''"
                      class="card-text mb-0"
                    >
                      <strong>{{ proposalDetail.listingInfo.level5 }}</strong>
                    </p>
                    <p
                      v-if="proposalDetail.listingInfo.level1 === null && proposalDetail.listingInfo.level2 === null && proposalDetail.listingInfo.level3 === null && proposalDetail.listingInfo.codigoPostal === null && proposalDetail.listingInfo.codigoPostal === ''"
                      class="card-text mb-0"
                    >
                      {{ proposalDetail.listingInfo.codigoPostal }}
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="Number(proposalDetail.iWhoami) === 1 || Number(proposalDetail.iWhoami) === 3 || Number(proposalDetail.iWhoami) === 5"
              md="12"
              class="ml-1 mb-1 p-2"
            >
              <b-row
                v-if="proposalDetail.agentExternal.id !== '' && (Number(proposalDetail.iWhoami) === 1 || Number(proposalDetail.iWhoami) === 5) && (Number(proposalDetail.visit.sw070s29) === 1 || Number(proposalDetail.visit.sw070s29) === 2)"
              >
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="proposalDetail.agentExternal.photo"
                    size="50px"
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h5 class="mb-0">
                        {{ proposalDetail.agentExternal.nome }}
                      </h5>
                      <h6
                        v-if="proposalDetail.agent.rede_imobiliaria !== ''"
                        class="card-text mb-0"
                      >
                        {{ proposalDetail.agentExternal.rede_imobiliaria }} {{ proposalDetail.agentExternal.agencia }}
                      </h6>
                      <span class="card-text">{{ proposalDetail.agentExternal.sw025s05 }}</span>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row
                v-else
              >
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="proposalDetail.user.imagem"
                    size="50px"
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h5 class="mb-0">
                        {{ proposalDetail.user.nomeUser }}
                      </h5>
                      <h6
                        v-if="proposalDetail.user.nameHUB !== ''"
                        class="card-text mb-0"
                      >
                        {{ proposalDetail.user.nameHUB }}
                      </h6>
                      <span class="card-text">{{ proposalDetail.user.contactoUser }}</span>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
            <b-col
              v-if="Number(proposalDetail.iWhoami) === 2 || Number(proposalDetail.iWhoami) === 4"
              md="12"
              class="ml-1 mb-1 p-2"
            >
              <b-row
                v-if="proposalDetail.agentExternal.id !== '' && Number(proposalDetail.iWhoami) === 2 && (Number(proposalDetail.visit.sw070s29) === 1 || Number(proposalDetail.visit.sw070s29) === 2)"
              >
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="proposalDetail.agentExternal.photo"
                    size="50px"
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h5 class="mb-0">
                        {{ proposalDetail.agentExternal.nome }}
                      </h5>
                      <h6
                        v-if="proposalDetail.agent.rede_imobiliaria !== ''"
                        class="card-text mb-0"
                      >
                        {{ proposalDetail.agentExternal.rede_imobiliaria }} {{ proposalDetail.agentExternal.agencia }}
                      </h6>
                      <span class="card-text">{{ proposalDetail.agentExternal.sw025s05 }}</span>
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row
                v-else
              >
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="proposalDetail.agent.imagem"
                    size="50px"
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h5 class="mb-0">
                        {{ proposalDetail.agent.nomeUser }}
                      </h5>
                      <h6
                        v-if="proposalDetail.agent.nameHUB !== ''"
                        class="card-text mb-0"
                      >
                        {{ proposalDetail.agent.nameHUB }}
                      </h6>
                      <span class="card-text">{{ proposalDetail.agent.contactoUser }}</span>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
            <b-col
              v-if="typeof proposalDetail.action !== 'undefined' && typeof proposalDetail.action.sw025_actionView !== 'undefined' && Number(proposalDetail.action.sw025_actionView) === 1"
              md="12"
              class="ml-1 mb-1 p-2"
            >
              <b-row>
                <div class="d-flex justify-content-start">
                  <b-avatar
                    size="50px"
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h5 class="mb-0">
                        {{ proposalDetail.visit.nomelead }}
                      </h5>
                      <span class="card-text">{{ proposalDetail.visit.sw025s05 }}</span>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-col>
          </b-row>

          <b-row
            v-if="Number(proposalDetail.proposal.sw130s07) === 1"
            class="p-2"
          >
            <b-col md="12">
              {{ $t('Vai recorrer a financiamento') }}
              &nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;&nbsp;
              <span v-if="proposalDetail.listing.currencyL !== ''">{{ $t('Valor') }}: <strong>{{ proposalDetail.listing.currencyL }} {{ formatPrice(proposalDetail.proposal.sw130s08) }}</strong></span>
              <span v-if="proposalDetail.listing.currencyR !== ''">{{ $t('Valor') }}: <strong>{{ formatPrice(proposalDetail.proposal.sw130s08) }} {{ proposalDetail.listing.currencyR }}</strong></span>
              &nbsp;&nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;&nbsp;
              <span v-if="proposalDetail.proposal.sw129s02 !== null && proposalDetail.proposal.sw129s02 !== ''">{{ $t('Banco') }}: <strong>{{ proposalDetail.proposal.sw129s02 }}</strong></span>
              <span v-else>{{ $t('Banco') }}: <strong>{{ $t('Ainda não definido') }}</strong></span>
            </b-col>
          </b-row>

          <b-row class="p-2">
            <b-col md="12">
              <timeline
                ref="timeline"
                :flow="proposalDetail.flow"
                :proposal="proposalDetail.proposal"
                :listing="proposalDetail.listing"
                :visit="proposalDetail.visit"
                :info-agent-external="proposalDetail.infoAgentExternal"
                :cargos="proposalDetail.cargos"
                :i-whoami="proposalDetail.iWhoami === '' ? 0 : Number(proposalDetail.iWhoami)"
                :accept-proposal="acceptProposal"
                :refuse-proposal="refuseProposal"
                :counter-proposal="counterProposal"
                :url-docs="proposalDetail.urlDocs"
                :final-approve-proposal="finalApproveProposal"
                :final-refuse-proposal="finalRefuseProposal"
              />
            </b-col>
          </b-row>

        </b-container>
      </vue-perfect-scrollbar>

      <b-overlay
        :show="$store.getters['crm_proposals/loadingListview']"
        no-wrap
      />

    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BOverlay, VBTooltip, BBadge, BImg, BAvatar, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BBadge,
    BImg,
    BAvatar,
    BContainer,
    VuePerfectScrollbar,
    timeline: () => import(/* webpackChunkName: "crm-proposals-detail-timeline" */ /* webpackPrefetch: true */ './detailTimeline.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
    openLeftSidebar: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['proposalDetail']),
    ...mapGetters('auth', ['countryApp']),
  },
  methods: {
    acceptProposal(type) {
      this.$parent.countProposal = 0
      this.$parent.acceptType = type
      this.$parent.showFormAcceptProcess = true
    },
    refuseProposal(type) {
      if (type === 1) {
        this.$parent.titleRefuse = this.$t('O proprietário recusou')
      } else {
        this.$parent.titleRefuse = this.$t('O comprador recusou')
      }

      this.$parent.showFormRefuseProcess = true
    },
    finalApproveProposal() {
      this.$parent.countProposal = 0
      this.$parent.acceptType = 0
      this.$parent.showFormAcceptProcess = true
    },
    finalRefuseProposal() {
      this.$parent.titleRefuse = this.$t('Recusar a proposta')
      this.$parent.showFormRefuseProcess = true
    },
    async counterProposal() {
      await this.$store.dispatch('crm_proposals_form/newFlowProposalModal', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01) }).then(async res => {
        if (res === true) {
          this.$parent.showFormCounterProposal = true
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-app-details {
  width: 100%!important;
  z-index: 3!important;
}

.email-application .email-app-details .email-user-list {
  width: 100%!important;
  height: calc( 100% - calc(4.9rem) )!important;
}
</style>
